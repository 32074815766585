<!-- 云端答疑模块-->
<template>
    <div class="question-home wrapper">
        <proTitle></proTitle>
        <!-- 列表组件 -->
        <div class="card-list-wrapper">
            <div class="card-list">
                <ul class="flex-align-between card-ul">
                    <li v-for="(item, index) in discussGroupList" :key="index" class="card csp"
                        @click="toQuestionList(item,index)">
                        <i class="card-type">{{item.period}}</i>
                        <p class="card-title wes-3">{{item.label}} 讨论区</p>
                        <div class="img-wrapper">
                            <img :src="downloadURL + item.cover"
                                :onerror="$store.state.discuss.defaultCommentCover[index % 6]" alt />
                        </div>
                    </li>
                    <li class="placeholder-card card"></li>
                </ul>
                <!-- <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                            :limit.sync="pages.pageSize" :pageSizes="[6,9,12]" @pagination="handlePageChange">
                        </pagination> -->
            </div>
            <!-- 无课程 -->
            <Empty :show="discussGroupList && discussGroupList.length === 0"></Empty>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        data() {
            return {
                discussGroupList: [], //讨论组列表
                pages: { //分页
                    currentPage: 1,
                    pageSize: 9,
                    total: 9,
                },
            };
        },
        watch: {
            '$store.state.user.currentProject.id': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.getDisccusGroup();
                }
            }
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
            })
        },
        methods: {
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
            },
            toQuestionList(item) {
                this.$store.commit('getDiscussInfo', {
                    name: `${item.label}讨论区`,
                    period: item.period
                })
                this.$router.push(`/question/${item.id}?breadNumber=4`)
            },
            // 获取讨论组
            async getDisccusGroup() {
                let params = {
                    projectId: this.currentProject.id
                }
                // console.log(params);
                let resData = await this.$Api.Course.getDisccusGroup(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '[]') return;
                this.discussGroupList = resData.data;
            },
        },
        mounted() {
            this.getDisccusGroup();
        },
    };
</script>

<style lang="less" scoped>
    .question-home {
        min-height: 500px;
        padding: 25px 20px;

        .card-ul {
            flex-flow: row wrap;

            .card {
                position: relative;
                box-sizing: border-box;
                margin: 0 0px 22px 0;
                border-radius: 10px;
                width: 31.7%;
                border: 1px solid #e8e8e8;
            }

            .placeholder-card {
                height: 0;
                padding: 0 !important;
                margin: 0 !important;
                border: 0;
            }

            .card-type {
                position: absolute;
                top: 15px;
                left: 0;
                border-top-right-radius: 25px;
                border-bottom-right-radius: 25px;
                background-color: #fdba23;
                line-height: 30px;
                padding: 0 15px;
                color: #fff
            }

            .card-title {
                position: absolute;
                top: 50%;
                left: 25px;
                max-width: 140px;
                font-size: 18px;
                transform: translateY(-35%);
                color: #fff;
                font-weight: 600;
            }

            //中间图片
            .img-wrapper {
                width: 100%;
                overflow: hidden;
                border-radius: 3px;

                img {
                    display: block;
                    width: 100%;
                    max-width: 100%;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }
            }
        }

        @media screen and (min-width: 1250px) {
            padding: 30px 25px;
        }

    }
</style>